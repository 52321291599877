import defaultTo from 'lodash/defaultTo'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import fromPairs from 'lodash/fromPairs'
import invert from 'lodash/invert'
import mapValues from 'lodash/mapValues'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import toPairs from 'lodash/toPairs'

import { countriesCs, countriesEn, currenciesCs, currenciesEn, phoneCodes } from '@/structures/MockSource'

const countries = {
  cs: countriesCs,
  en: countriesEn
}

const currencies = {
  cs: currenciesCs,
  en: currenciesEn
}

const autocompleteValues = new Map([
  ['firstname', 'given-name'],
  ['lastname', 'family-name'],
  ['street', 'address-line1'],
  ['street2', 'address-line2'],
  ['city', 'address-level2'],
  ['zip', 'postal-code'],
  ['companyName', 'organization'],
  ['currency', 'transaction-currency'],
  ['currentPassword', 'current-password'],
])

function getAutocomplete(value) {
  let id = value

  if(autocompleteValues.has(value)) {
    id = autocompleteValues.get(value)
  }

  return id
}

/**
 *
 * @param name
 * @param modelName
 * @param label
 * @param type
 * @param inputmode
 * @param placeholder
 * @param autocomplete
 * @param required
 * @param {Function[]} rules
 * @param disabled
 * @param layout
 * @param options
 * @param labels
 * @param {boolean} allOption
 * @param {string} accept
 * @param {boolean} arrayOptions
 * @param {boolean} ignoreValidation
 * @param {boolean} inline
 * @constructor
 */
function FormFieldEntity ({
                            name = '',
                            modelName = name,
                            label = '',
                            type = 'text',
                            inputmode = 'text',
                            placeholder = label,
                            autocomplete = name,
                            required = false,
                            rules = [],
                            ignoreValidation = false,
                            disabled = false,
                            layout = 'full',
                            options = [],
                            labels = null,
                            allOption = false,
                            accept = '',
                            arrayOptions = false,
                            inline = false,
                          }) {
  const assignedAutocomplete = getAutocomplete(autocomplete)
  Object.assign(this, {
    name,
    modelName,
    label,
    type,
    inputmode,
    placeholder,
    autocomplete: assignedAutocomplete,
    required,
    disabled,
    layout,
    options,
    rules,
    ignoreValidation,
    allOption,
    labels,
    accept,
    arrayOptions,
    inline
  })
}

/**
 *
 * @param property {String}
 * @param value
 */
FormFieldEntity.prototype.setConfigItem = function (property, value) {
  this[property] = value
}

export function countryOptions (localization = 'en') {
  if (localization in countries) {
    return countries[localization]
  }

  return countries.en
}

export function availableShippingCountryOptions (localization = 'en') {
  const availableCodes = [
    'AL',
    'GB',
    'AR',
    'AM',
    'AU',
    'PT',
    'BE',
    'BY',
    'BA',
    'BR',
    'BG',
    'ME',
    'CZ',
    'ES',
    'CL',
    'HR',
    'CN',
    'DK',
    'EG',
    'EE',
    'FO',
    'PH',
    'FI',
    'FR',
    'GI',
    'GL',
    'HK',
    'IN',
    'ID',
    'IE',
    'IS',
    'IT',
    'IL',
    'JP',
    'ZA',
    'KR',
    'CA',
    'ES',
    'KZ',
    'CO',
    'XK',
    'CY',
    'KG',
    'LI',
    'LT',
    'LV',
    'LU',
    'HU',
    'PT',
    'MK',
    'MY',
    'MT',
    'MA',
    'MX',
    'MD',
    'MC',
    'MN',
    'DE',
    'NL',
    'GB',
    'NO',
    'NZ',
    'PY',
    'PE',
    'PL',
    'PR',
    'PT',
    'AT',
    'GR',
    'RE',
    'RO',
    'RU',
    'SM',
    'SC',
    'SG',
    'SK',
    'SI',
    'ES',
    'AE',
    'US',
    'RS',
    'SE',
    'CH',
    'TW',
    'TH',
    'TN',
    'TR',
    'UA',
    'VE',
    'VN',
    'GB'
  ]
  let countriesList = countries.en

  if (localization in countries) {
    countriesList = countries[localization]
  }
  countriesList = {...countriesList}

  const countryKeys = Object.keys(countriesList)
  const keysToRemove = filter(countryKeys, key => !availableCodes.includes(key))

  forEach(keysToRemove, key => {
    delete countriesList[key]
  })

  return countriesList
}

export function currencyOptions (localization = 'en') {
  if (localization in currencies) {
    return currencies[localization]
  }

  return currencies.cs
}

export function phoneCodesOptions(localization = 'en', arrayOutput = false) {
  let countriesList = countries.cs

  if (localization in countries) {
    countriesList = countries[localization]
  }

  let codesList = mapValues(phoneCodes, (value) => {
    let newValue = value

    if (!newValue.includes('+')) {
      newValue = `+${newValue}`
    }

    return newValue
  })

  codesList = toPairs(codesList)
  codesList = sortBy(codesList, 0)
  codesList = fromPairs(codesList)

  codesList = invert(codesList)
  codesList = mapValues(codesList, (countryCode, phoneCode) => {
    let country = countriesList[countryCode]
    country = defaultTo(country, '')

    return country
  })

  codesList = toPairs(codesList)
  codesList = codesList.sort(function (a, b) {
    return a[1].localeCompare(b[1]);
  })
  codesList = fromPairs(codesList)

  codesList = mapValues(codesList, (country, phoneCode) => {

    return `${phoneCode.padEnd(5)} - ${country}`
  })

  return codesList
}

export function phoneCodesOptionsArray(localization = 'en') {
  let countriesList = countries.cs

  if (localization in countries) {
    countriesList = countries[localization]
  }

  let codesList = mapValues(phoneCodes, (value) => {
    let newValue = value

    if (!newValue.includes('+')) {
      newValue = `+${newValue}`
    }

    return newValue
  })

  codesList = toPairs(codesList)
  codesList = sortBy(codesList, 0)
  codesList = fromPairs(codesList)

  codesList = map(codesList, (phoneCode, countryCode) => {
    return {
      value: phoneCode,
      text: defaultTo(countriesList[countryCode], '')
    }
  })
  codesList = filter(codesList, ({value}) => value !== '+')


  codesList = codesList.sort(function (a, b) {
    return a.text.localeCompare(b.text);
  })
  codesList = map(codesList, ({value, text}) => {
    return {
      value,
      text: `${value.padEnd(5)} - ${text}`
    }
  })

  return codesList
}

export const FormField = FormFieldEntity
