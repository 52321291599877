import { dayMonthYear } from '@/services/DateService'

/**
 * @typedef {Object} EstimateDeliveryMethod
 * @typedef {Number} EstimateDeliveryMethod.estimate_time_min
 * @typedef {Number} EstimateDeliveryMethod.estimate_time_max
 * @typedef {String} EstimateDeliveryMethod.estimate_date_min
 * @typedef {String} EstimateDeliveryMethod.estimate_date_max
 *
 */
export default class EstimateDeliveryMethod {
  /**
   * @typedef {String} EstimateDeliveryMethod.Type
   * @enum {EstimateDeliveryMethod.Type}
   */
  static Type = {
    instorePickup: 'IN_STORE_PICKUP',
    zasilkovna: 'ZASILKOVNA',
    gls: 'GLS',
    czechPost: 'CZECH_POST',
    ups: 'UPS',
    deutschePost: 'DEUTSCHE_POST',
    other: 'OTHER'
  }

  /**
   *
   * @param {Object} props
   * @param {EstimateDeliveryMethod.Type} props.type
   * @param {String} props.estimate_date_min
   * @param {String} props.estimate_date_max
   * @param {Number} props.estimate_time_min
   * @param {Number} props.estimate_time_max
   */
  constructor (props) {
    Object.assign(this, props)
  }

  get estimateTimeMin () {
    return this.estimate_time_min
  }

  get estimateTimeMax () {
    return this.estimate_time_max
  }

  get estimateDateMinFormatted () {
    return dayMonthYear(this.estimate_date_min)
  }

  get estimateDateMaxFormatted () {
    return dayMonthYear(this.estimate_date_min)
  }
}
