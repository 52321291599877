import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'

class ApiError {
  constructor (error, url = null, worker = false) {
    this.name = 'ApiError'
    this.code = ''
    this.message = ''
    this.statusCode = 0
    this.value = null
    this.reason = null
    this.isCancelled = false
    this.endpoint = url
    this.worker = worker

    if (isNull(error)) {
      this.message = 'No internet connection'
    } else if ('response' in error && isObject(error.response)) {
      this.copyDefaults(error)
      if ('data' in error.response) {
        const data = error.response.data
        this.value = data

        if (!isObject(data)) {
          this.message = `Network Error`
          return
        }

        const dataKeys = Object.keys(data)

        if(!isArray(data) && isObject(data) && 'code' in data) {
          this.message = data.code
        } else if (dataKeys && !isEmpty(dataKeys)) {
          const subject = capitalize(dataKeys[0])
          let info = ''

          if (isObject(data[dataKeys[0]])) {
            const objectKeys = Object.keys(data[dataKeys[0]])
            info = data[dataKeys[0]][objectKeys[0]].message
          } else {
            info = data[dataKeys[0]][0].message
          }
          this.message = `${subject}: ${info}`

          if (dataKeys[0] === 'non_field_errors') {
            this.message = `${data[dataKeys[0]][0].message}`
            this.reason = data[dataKeys[0]][0].code
            return
          }
        }
      }

      if (error && 'status' in error) {
        this.statusCode = error.response.status || 0
      } else {
        this.copyDefaults(error)
      }
    } else {
      this.copyDefaults(error)
    }
  }

  hasField(key) {
    return isObject(this.value) && key !== null && key in this.value
  }

  /**
   *
   * @param {string} key
   * @returns {string|null}
   */
  getCode (key = null) {
    let codeId = this.code

    if (isObject(this.value) && !isArray(this.value)) {

      if (key === null && 'code' in this.value) {
        // If not searching for specific code
        codeId = this.value.code
      } else if(key === null) {

        // eslint-disable-next-line no-unreachable-loop
        for(const key in this.value) {
          const firstItem = this.value[key]

          if(isObject(firstItem) && !isArray(firstItem)) {
            if('code' in firstItem) {
              codeId = firstItem.code
            } else {
              // eslint-disable-next-line no-unreachable-loop
              for (const innerKey in firstItem) {
                const firstInnerItem = firstItem[innerKey]

                if(isArray(firstInnerItem) && !isEmpty(firstInnerItem) && isObject(firstInnerItem[0]) && 'code' in firstInnerItem[0]) {
                  codeId = firstInnerItem[0].code
                }
                break
              }

            }

          }
          break
        }

      } else if (key && key in this.value) {
        if (isArray(this.value[key]) && !isEmpty(this.value[key])) {
          const { code } = this.value[key][0]

          if (code) {
            codeId = code
          }
        }
      }

    } else if (isArray(this.value) && !isEmpty(this.value)) {
      const { code } = this.value[0]

      if (code) {
        codeId = code
      }
    }

    return codeId
  }

  getMessage () {
    let message = this.message

    if (isObject(this.value) && 'message' in this.value) {
      message = this.value.message
    } else if (isObject(this.value) && !isEmpty(this.value)) {
      const keys = Object.keys(this.value)
      const firstItem = this.value[keys[0]]

      if(isArray(firstItem) && !isEmpty(firstItem) && isObject(firstItem[0]) && 'message' in firstItem[0]) {
        message = firstItem[0].message
      } if(isArray(firstItem) && !isEmpty(firstItem) && isString(firstItem[0])) {
        message = firstItem[0]
      } else if(isObject(firstItem) && 'message' in firstItem) {
        message = firstItem.message
      } else if (isObject(firstItem)) {
        const innerKeys = Object.keys(firstItem)
        const innerValue = firstItem[innerKeys[0]]

        if(isArray(innerValue) && !isEmpty(innerValue) && 'message' in innerValue[0]) {
          message = innerValue[0].message
        }
      }
    }

    return message
  }

  getColor () {
    return this.statusCode < 400 ? 'green' : 'red'
  }

  copyDefaults (error) {
    if ('response' in error && isObject(error.response)) {
      this.code = error.response.status
      this.statusCode = error.response.status
      this.message = error.response.statusText
    } else {
      this.code = error.code
      this.statusCode = error.code
      this.message = error.message
    }
  }
}

export default ApiError
