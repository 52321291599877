import CountriesCs from '@/mock/countries/cs'
import CountriesEn from '@/mock/countries/en'
import { EUcountries, EFTACountries } from '@/mock/countries/EU'

import PhoneCodes from '@/mock/phoneCodes/iso'

import CurrenciesCs from '@/mock/currencies/cs'
import CurrenciesEn from '@/mock/currencies/en'

export function mockCacheInterval (minutes = 30) {
  return minutes * 60 * 1000
}

/**
 * @param {number} cacheInterval
 * @param {number} lastTimestamp
 * @returns {boolean}
 */
export function isCacheExpired (cacheInterval, lastTimestamp) {
  return !((Date.now() - lastTimestamp) < cacheInterval)
}

export const countriesCs = CountriesCs
export const countriesEn = CountriesEn

export const currenciesCs = CurrenciesCs
export const currenciesEn = CurrenciesEn

export const euCountries = EUcountries
export const eftaCountries = EFTACountries

export const phoneCodes = PhoneCodes

/**
 *
 * @param {string} countryShortcut
 * @param {string} loc
 * @param {{cs: {}, en: {}}} countries
 * @returns {*}
 */
export function getCountryLabel(countryShortcut, loc) {
  let countriesLocalized = countriesEn

  if(loc === 'cs') countriesLocalized = countriesCs

  return countriesLocalized[countryShortcut] || countryShortcut
}

export function isEUCountry(country) {
  return [...EUcountries, ...EFTACountries].includes(country)
}
