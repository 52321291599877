import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import { getLoc } from '~/structures/Localizable'

class SidebarSummaryItem {
  // eslint-disable-next-line camelcase
  localized_name = ''
  name = ''
  // eslint-disable-next-line camelcase
  name_cz = ''
  // eslint-disable-next-line camelcase
  name_de = ''
  /**
   *
   * @param {Object} props
   * @param {string} props.id
   * @param {string?} props.localized_name
   * @param {string?} props.name
   * @param {string?} props.name_cz
   * @param {string?} props.name_de
   * @param {string?} props.image_url
   * @param {string?} props.icon_url
   * @param {number?} props.total_count
   * @param {string?} props.game
   * @param {string?} props.url
   * @param {Array?} props.children
   */
  constructor (props) {
    this.id = props.id
    this.image = props.image_url || props.icon_url || ''
    this.count = props.total_count || 0
    this.game = props.game || null
    this.url = props.url || null
    this.children = map(props.children || [], item => new SidebarSummaryItem(item))

    this.localized_name = props.localized_name
    this.name = props.name
    this.name_cz = props.name_cz
    this.name_de = props.name_de
  }

  get titleLoc() {
    return getLoc(this.name, this.name_cz, this.name_de)
  }
}

export default SidebarSummaryItem

/**
 *
 * @param {{}} summary
 * @return {{}}
 */
export function mapSummary(summary) {
  return mapValues(summary, items => {
    return map(items, item => new SidebarSummaryItem(item))
  })
}
