/**
 *
 * @param {string|null} en
 * @param {string|null} cs
 * @param {string|null} de
 * @returns {{cs: string, de: string, en: string}}
 */
export function getLoc(en, cs = en, de = en) {
  return {en, cs, de}
}

/**
 *
 * @param {number|null} czk
 * @param {number|null} eur
 * @returns {{eur: string|number, czk: string|number}}
 */
export function getLocCurrency(czk, eur = czk) {
  return {czk, eur}
}

/**
 *
 * @param {{string:string|number}} values
 * @param {string} loc
 * @returns {null|string|number}
 */
export function decodeLoc(values, loc) {
  let value = null

  if(values && loc in values) value = values[loc]

  return value
}
