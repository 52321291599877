import { consola } from 'consola'

let parser  = function(number){
  throw new Error('Phone number parser not loaded')
}

let validator = function(number){
  throw new Error('Phone number validator not loaded')
}

export function parsePhoneNumberFromString(number) {
  return parser(number)
}

export function isValidPhoneNumber(number) {
  return validator(number)
}

export async function loadParser() {
  try {
    const module = await import('libphonenumber-js/max')
    parser = module.parsePhoneNumberFromString
    validator = module.isValidPhoneNumber
  } catch (e) {
    consola.error(e)
  }
}
