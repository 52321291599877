import FilterModel from '@/structures/filters/FilterModel'

export default class SinglesFilter extends FilterModel {
  static defaultFilters = function (currency) {
    return {
      q: '',
      expansion: [],
      rarity: [],
      supertype: [],
      mtg_colors: ['or'],
      article_foil: [],
      mtg_legal: [],
      article_language: [],
      article_condition: [],
      article_price: currency === 'czk' ? [0, 1000] : [0, 40],
      mtg_types: [],
      mtg_subtypes: [],
      mtg_text: '',
      mtg_artist: [],
      mtg_cmc: [0, 15],
      mtg_power: [0, 15],
      mtg_toughness: [0, 15]
    }
  }
}
