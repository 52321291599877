import isEmpty from 'lodash/isEmpty'

import Codable from '@/database/models/Codable'
import Article from '@/database/models/Article'

class OrderItem extends Codable {
  static entity = 'orderitems'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      article: this.attr(null),
      category_tag: this.attr(null),
      count: this.attr(0),
      custom_name: this.attr(null),
      base_unit_price: this.attr(null),
      effective_unit_price: this.attr(null),
      base_unit_price_without_vat: this.attr(null),
      effective_unit_price_without_vat: this.attr(null),
      discount_type: this.attr(null),
      voucher: this.attr(null)
    }
  }

  get basePrice () {
    return this.base_unit_price
  }

  get effectivePrice () {
    return this.effective_unit_price
  }

  get basePriceWithoutVat () {
    return this.base_unit_price_without_vat
  }

  get effectivePriceWithoutVat () {
    return this.effective_unit_price_without_vat
  }

  get totalPrice () {
    return this.effectivePrice * this.count
  }

  get articleModel () {
    return new Article(this.article || {})
  }

  get hasArticle () {
    return this.article !== null
  }

  get isFoil () {
    let foil = false

    if(this.hasArticle) {
      foil = this.articleModel.isFoil
    }

    return foil
  }

  get categoryTag () {
    return this.category_tag || 'Unknown'
  }

  get otherTags () {
    return this.articleModel.otherTags
  }

  get category () {
    let name

    if (!isEmpty(this.otherTags)) {
      name = this.otherTags[0].title
    }

    return name
  }

  get customName () {
    return this.custom_name
  }

  get description () {
    const items = []
    const articleObject = this.articleModel

    if (articleObject.expansionTitle) items.push(articleObject.expansionTitle)
    if (articleObject.isFoil) items.push('Foil')
    if (articleObject.condition) items.push(articleObject.condition)

    return items.join(', ')
  }

  get descriptionPartsLoc() {
    return this.articleModel.descriptionPartsLoc
  }

  get isCheckoutReward () {
    return (this.article !== null || !isEmpty(this.customName)) && this.basePrice === 0
  }
}

export default OrderItem
