import FilterModel from '@/structures/filters/FilterModel'

export default class NonSinglesFilter extends FilterModel {
  static defaultFilters = function (currency) {
    return {
      product_expansion: [],
      product__expansion__game: [],
      product_category: [],
      product_tags: []
    }
  }
}
