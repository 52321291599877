import filter from 'lodash/filter'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'

import RecentlyVisitedArticle from '@/database/models/RecentlyVisitedArticle'

const ItemsLimit = 8

export const state = () => ({
  items: []
})

export const mutations = {
  'SET_ITEMS': (state, {items}) => {
    state.items = items
  }
}

export const actions = {
  addItem ({ dispatch, getters }, { article }) {
    article.orm_timestamp = Date.now()

    const items = [...getters.recentItemsNonfiltered]
    items.push(new RecentlyVisitedArticle(article))

    dispatch('refreshItemsStorage', {items})
  },

  refreshItemsStorage ({commit}, {items}) {
    let orderedItems = orderBy(items, 'orm_timestamp')
    orderedItems.reverse()
    orderedItems = uniqBy(orderedItems, 'id')

    if (orderedItems.length > ItemsLimit * 2) {
      /** @type {Array<String>} */
      const toRemove = map(items.splice(ItemsLimit * 2, items.length) || [], item => item.id)
      orderedItems = filter(orderedItems, item => !toRemove.includes(item.id))
    }

    commit('SET_ITEMS', {items: orderedItems})
  }
}

export const getters = {
  recentItemsNonfiltered: (state) => {
    return map(state.items, item => new RecentlyVisitedArticle(item))
  },

  recentItems: (state, getters, rootState, rootGetters) => {
    const cartItems = rootGetters['cart/cartItems']
    const recentlyVisited = getters.recentItemsNonfiltered
    const productIds = map(cartItems, item => {
      if (item.article) {
        return item.article.id
      }

      return null
    })

    return filter(recentlyVisited, item => !productIds.includes(item.id)).slice(0, ItemsLimit)
  }
}
