import AutoCodableN from '~/database/models/AutoCodableN'

export default class ShippingAccessPoint extends AutoCodableN {
  static fields () {
    return {
      balikobot_id: this.attr(1),
      city: this.attr(null),
      country: this.attr(null),
      name: this.attr(null),
      shipping_method_type: this.attr(null),
      street: this.attr(null),
      zip: this.attr(null)
    }
  }

  static propertyMap = new Map([
    ['id', 'balikobot_id'],
    ['shippingMethodType', 'shipping_method_type']
  ])
}
