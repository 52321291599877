import filter from 'lodash/filter'
import map from 'lodash/map'
import WantlistItem from '@/database/models/WantlistItem'
import { findObject } from '~/services/Helpers'
import LogService from '~/services/LogService'
import { runTask } from '~/services/network/utils/axios'

import {
  createWantlistItem,
  getUserWantlistItems,
  updateWantlistItem,
  updateWantlistItems
} from '~/workers/network.worker'

export const state = () => ({
  isLoading: false,
  items: []
})

export const mutations = {
  'SET_LOADING' (state, value) {
    state.isLoading = value
  },
  'SET_ITEMS' (state, items) {
    state.items = items
  }
}

export const actions = {
  async fetchItems ({ commit, getters }) {
    if (getters.isLoading) return

    let hasMore = true
    let page = 0
    const limit = 20
    commit('SET_LOADING', true)
    const items = []

    try {
      while (hasMore) {
        /**
         * @type {FetchResult}
         */
        const result = await runTask(getUserWantlistItems({
          cancelId: 'vuex-wantlists-get',
          query: {
            limit,
            offset: limit * page
          }
        }))
        page++
        hasMore = result.hasMore
        items.push(...result.data)
        commit('SET_ITEMS', [...items])
      }
    } catch (e) {
      LogService.error(e)
    }

    commit('SET_LOADING', false)
  },

  async createWantlistItem ({ dispatch }, { ...data }) {
    await runTask(createWantlistItem({ data: WantlistItem.mapProperties(data) }))
    await dispatch('fetchItems')
  },
  async updateWantlistItem ({commit, getters}, { id, ...data }) {
    let wantlistItems = [...getters.items]
    const result = await runTask(updateWantlistItem({id, data: WantlistItem.mapProperties(data)}))

    // When wantlist item is activated, the id changes so we have to remove it first
    if (result.id !== id) {
      wantlistItems = filter(wantlistItems, wantlistItem => wantlistItem.id !== id)
    }

    wantlistItems.push(result)
    commit('SET_ITEMS', wantlistItems)
  },
  setWantlistItemActive ({ dispatch }, { id }) {
    return dispatch('updateWantlistItem', {
      id,
      customer_status: WantlistItem.CustomerStatus.active
    })
  },
  async removeWantlistItems ({ commit, getters }, { ids = [] }) {
    const remainingItems = filter(getters.items, wantlist => !ids.includes(wantlist.id))
    commit('SET_ITEMS', remainingItems)

    await runTask(updateWantlistItems({ data: { ids, customer_status: WantlistItem.CustomerStatus.deleted } }))
  },

  removeAll ({commit}) {
    commit('SET_ITEMS', [])
  }
}

export const getters = {
  isLoading: state => {
    return state.isLoading
  },
  items: (state) => {
    return map(state.items, item => new WantlistItem(item))
  },
  itemsCount: (state, getters) => {
    return getters.items.length
  },
  wantlistedItems: (state) => {
    return state.items.map(item => item.product?.id)
  },
  isWantlisted: (_, getters) => productId => {
    return getters.wantlistedItems.includes(productId)
  },
  wantlistByProduct: (_, getters) => (productId) => {
    return findObject(getters.items, wantlistItem => {
      return wantlistItem.product && wantlistItem.product.id === productId
    })
  }
}
