import isEmpty from 'lodash/isEmpty'
import FilterModel from '@/structures/filters/FilterModel'
import { dayMonthYearISO, endOfMonth, endOfYear, startOfMonth, startOfYear } from '@/services/DateService'


export default class CMSArticleFilter extends FilterModel {
  static defaultFilters = function (currency) {
    return {
      created: {
        month: null,
        year: null
      },
      tags: [],
      titles: []
    }
  }

  /**
   * @return {{}}
   */
  serialize () {
    const filters = { ...this }
    let createdAfter = null
    let createdBefore = null

    if (!filters.created.year) {
      filters.created_before = null
      filters.created_after = null
    } else {
      const date = new Date()
      date.setFullYear(filters.created.year)

      if (filters.created.month || filters.created.month === 0) {
        date.setMonth(filters.created.month)
        createdAfter = dayMonthYearISO(startOfMonth(date.toISOString()).toISOString())
        createdBefore = dayMonthYearISO(endOfMonth(date.toISOString()).toISOString())
      } else {
        createdAfter = dayMonthYearISO(startOfYear(date.toISOString()).toISOString())
        createdBefore = dayMonthYearISO(endOfYear(date.toISOString()).toISOString())
      }
    }

    filters.created_before = createdBefore
    filters.created_after = createdAfter
    delete filters.created

    if (!isEmpty(filters.tags)) {
      filters.tags = filters.tags.join(',')
    }
    if (!isEmpty(filters.titles)) {
      filters.titles = filters.titles.join(',')
    }

    return filters
  }
}
