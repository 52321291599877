import each from 'lodash/each'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import LogService from '@/services/LogService'

export default class SummaryActiveManager {
  groups = {}

  /**
   * @param {string[]} keys
   */
  constructor (
    {
      keys = []
    }
  ) {
    const oThis = this

    each(keys, key => {
      oThis.groups[key] = []
    })
  }

  /**
   *
   * @param {string} key - Group section identifier, e.g. product_expansion
   * @param {string|number} id - Identifier of item to toggle
   */
  toggleItem (key, id) {
    if (key in this.groups) {
      const set = new Set(this.groups[key])

      if (set.has(id)) {
        set.delete(id)
      } else {
        set.add(id)
      }

      this.groups[key] = Array.from(set)
    }
  }

  /**
   *
   * @param {string} key
   * @param {string[]|number[]} values
   */
  setItems (key, values = []) {
    if (key in this.groups && isArray(values)) {
      this.groups[key] = values
    } else {
      LogService.warn(`Error setting items for key ${key} and values ${values}`)
    }
  }

  /**
   * Adds group section
   *
   * @param {string} key
   */
  addKey (key) {
    if (!(key in this.groups)) {
      this.groups[key] = []
    }
  }

  /**
   * Resets all values for all groups
   */
  reset () {
    const oThis = this

    each(Object.keys(this.groups), section => {
      oThis.groups[section] = []
    })
  }

  /**
   * Evaluates if every section is empty
   * @return {boolean}
   */
  isEmpty () {
    const oThis = this
    let isAllEmpty = true

    each(Object.keys(this.groups), section => {
      isAllEmpty = isEmpty(oThis.groups[section])
      return isAllEmpty
    })

    return isAllEmpty
  }
}
