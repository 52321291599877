import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

class Voucher {
  constructor (props) {
    Object.assign(this, props)
  }

  static Type = {
    absolute: 'ABSOLUTE_DISCOUNT',
    percentage: 'PERCENTAGE_DISCOUNT',
    credit: 'CREDIT',
  }

  static Error = {
    voucherInvalid: 'voucher_invalid',
    wrongVoucherType: 'wrong_voucher_type',
    wrongPromoActionType: 'wrong_promo_action_type',
    minimumValueNotMet: 'minimum_order_value_for_voucher_not_met',

    voucherExpired: 'promo_action_expired',
    forAuthenticatedUsersOnly: 'promo_action_limited_for_authenticated_user',
    maxUsageExceeded: 'promo_action_max_usage_exceeded',
    oneCustomerOnly: 'promo_action_limited_one_for_customer',
    customerJoinedFromOnly: 'promo_action_limited_for_customer_joined_from',
    customerJoinedToOnly: 'promo_action_limited_for_customer_joined_to'
  }

  get isAbsoluteDiscount () {
    return this.type === Voucher.Type.absolute
  }

  get discountPercentage () {
    return this.discount_percentage || 0
  }

  get isPercentageDiscount () {
    return this.discount_percentage !== null
  }

  get validUntil () {
    return this.valid_until
  }

  get isValid () {
    return !isNull(this.code) && !isEmpty(this.code)
  }

  discount (currency = 'CZK') {
    return this[`discount_${currency.toLowerCase()}`]
  }
}

export default Voucher
