import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import ShoppingList from '~/database/models/ShoppingList'
import {until} from '~/services/Helpers'
import LogService from '~/services/LogService'
import {
  addShoppingListItem,
  createShoppingListFromCart,
  getShoppingList,
  deleteShoppingList,
  getShoppingLists,
  updateShoppingList,
  createShoppingList,
  addShoppingListToCart,
  updateShoppingListItem,
  deleteShoppingListItem,
  cancelSignal,
  getLastShoppingList
} from '~/workers/network.worker'
import { runTask } from '~/services/network/utils/axios'

const GET_LISTS_IDENTIFIER = 'vuex-shoppingLists-get'

export const state = () => ({
  isLoading: false,
  lists: [],
  last: null
})

export const mutations = {
  'SET_LOADING' (state, value) {
    state.isLoading = value
  },
  'SET_ITEMS' (state, items) {
    state.lists = items
  },
  'SET_LAST' (state, last) {
    state.last = last
  }
}

export const actions = {
  cancelGetter() {
    cancelSignal(GET_LISTS_IDENTIFIER)
  },

  async getLastShoppingList({commit}) {
    let last = null

    try {
      const result = await runTask(getLastShoppingList({
        query: {
          visited: false
        }
      }))

      if(isObject(result) && 'item' in result) {
        last = result
      }

      commit('SET_LAST', last)
    } catch (e) {
      LogService.error(e)
    }
  },

  async getShoppingLists({commit, getters}) {
    const previousLists = getters.lists
    const cancelIdentifier = GET_LISTS_IDENTIFIER
    if (getters.isLoading) {
      return
    }

    let hasMore = true
    let page = 0
    const limit = 10
    commit('SET_LOADING', true)
    const items = []

    while (hasMore) {
      try {
        /**
         * @type {FetchResult}
         */
        const result = await runTask(getShoppingLists({
          cancelId: cancelIdentifier,
          query: {
            limit,
            offset: limit * page
          }
        }))

        page++
        hasMore = result.hasMore
        items.push(...result.data)
      } catch (e) {
        hasMore = false
        commit('SET_LOADING', false)
        return
      }
    }

    for (const item of items) {
      const previousList = find(previousLists, list => list.id === item.id)

      if(previousList && (isEmpty(item.items) || !('items' in item))) {
        item.items = [...previousList.items || []]
      }
    }

    commit('SET_ITEMS', [...items])
    commit('SET_LOADING', false)
  },

  async createShoppingList({dispatch}, {name}) {
    const result = await runTask(createShoppingList({
      data: {name, items: []}
    }))
    await dispatch('getShoppingLists')
    return new ShoppingList(result)
  },

  async addShoppingListToCart({dispatch}, {id, type}) {
    await runTask(addShoppingListToCart({
      id,
      data: {type}
    }))
    dispatch('cart/refreshCart', {slRefresh: false}, {root: true})
  },

  async updateShoppingList ({ commit, dispatch, getters }, { id, ...data }) {
    await runTask(updateShoppingList({ id, data: ShoppingList.mapProperties(data || {}) }))

    dispatch('getShoppingLists')
  },

  async updateShoppingListItem ({ dispatch }, { shoppingListId, id, ...data }) {
    await runTask(updateShoppingListItem({
      id,
      data
    }))

    await dispatch('updateShoppingList', { id: shoppingListId })
  },

  deleteShoppingListItem({commit, getters}, {id}) {
    const lists = [...getters.lists]
    for (const list of lists) {
      list.items = filter(list.items, item => item.id !== id)
    }

    commit('SET_ITEMS', lists)
    return runTask(deleteShoppingListItem({id}))
  },

  deleteShoppingList({commit, getters}, {id}) {
    commit('SET_ITEMS', filter(getters.lists, list => list.id !== id))
    return runTask(deleteShoppingList({id}))
  },

  async setShoppingListsAsVisited ({ dispatch, getters }) {
    const automaticLists = getters.automaticListsNotVisited

    for (const automaticList of automaticLists) {
      await dispatch('updateShoppingList', {
        id: automaticList.id,
        visited: true
      })
    }
  },

  async addItemToShoppingList({commit, dispatch, getters}, {id, listItem}) {
    const lists = [...getters.lists]
    await runTask(addShoppingListItem({
      id,
      data: {
        ...listItem
      }
    }))
    const result = await runTask(getShoppingList({id}))
    const existingListIndex = findIndex(lists, list => list.id === id)

    if(existingListIndex > -1) {
      lists[existingListIndex] = result
      commit('SET_ITEMS', lists)
    } else {
      dispatch('getShoppingLists')
    }
  },

  async transformCartToShoppingList({dispatch}, {existingList = null}) {
    const payload = {
      existing_shopping_list: existingList
    }

    await runTask(createShoppingListFromCart({data: payload}))
    return dispatch('getShoppingLists')
  },

  async setShoppingListItems({commit, getters, state}, {id, items}) {
    if(getters.isLoading) {
      await until(() => {
        return getters.isLoading === false
      })
    }

    const lists = [...state.lists]
    const listIndex = findIndex(lists, list => list.id === id)

    if(listIndex > -1) {
      const list = {...lists[listIndex]}
      list.items = items
      lists[listIndex] = list
      commit('SET_ITEMS', lists)
    }
  },

  clear({commit}) {
    commit('SET_ITEMS', [])
  },
  clearLastShoppingList({commit, dispatch, getters}) {
    const isCartAlertRequested = getters.isCartAlertRequested

    if(isCartAlertRequested) {
      const lastShoppingList = getters.lastShoppingList
      try {
        dispatch('updateShoppingList', {
          id: lastShoppingList.id,
          visited: true
        })
      } catch (e) {
        LogService.error(e)
      }
    }

    commit('SET_LAST', null)
  }
}

export const getters = {
  isLoading: state => {
    return state.isLoading
  },

  lists: state => {
    return map(state.lists, list => new ShoppingList(list))
  },
  hasLists: state => {
    return state.lists.length > 0
  },
  listItemIds: (state, getters) => {
    return reduce(getters.lists, (items, list) => {
      return [...items, ...map(list.items, item => item.product?.id)]
    }, [])
  },
  isInShoppingList: (state, getters) => id => {
    return getters.listItemIds.includes(id)
  },
  getShoppingListForProduct: (state, getters) => productId => {
    const lists = getters.lists
    let listId = null

    for (const list of lists) {
      const listItem = find(list.items, item => item.product?.id === productId)

      if(listItem) {
        listId = list.id
        break;
      }
    }

    return listId
  },
  getShoppingListNamesForProduct: (state, getters) => productId => {
    const lists = getters.lists
    const listNames = []

    for (const list of lists) {
      const listItem = find(list.items, item => item.product?.id === productId)

      if(listItem) {
        listNames.push(list.name)
      }
    }

    return listNames
  },

  automaticListsNotVisited: (state, getters) => {
    const filtered = state.lists.filter(list => list.is_automatic && !list.visited)
    return filtered.map(list => new ShoppingList(list))
  },

  isCartAlertRequested: (state, getters) => {
    return state.last !== null
  },
  lastShoppingList: state => {
    return new ShoppingList(state.last)
  }
}
