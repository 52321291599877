import AutoCodableN from '~/database/models/AutoCodableN'

class PaymentMethod extends AutoCodableN {
  static entity = 'payment_methods'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      type: this.attr(''),
      icon: this.attr(''),
      localized_name: this.attr(''),
      name: this.attr(''),
      name_cz: this.attr(''),
      name_de: this.attr(''),
      description: this.attr(''),
      description_cz: this.attr(''),
      description_de: this.attr(''),
      provider: this.attr(''),
      price_czk: this.attr(0),
      price_eur: this.attr(0),
      credit_remaining: this.attr(0),
      disabled: this.attr(false)
    }
  }

  get titleLoc() {
    return this.getLocWithDefault('name')
  }

  get priceLoc() {
    return this.getLocCurrency('price')
  }

  get descriptionLoc() {
    return this.getLocWithDefault('description')
  }

  get creditRemaining () {
    return this.credit_remaining
  }

  get value () {
    return this.type
  }
}

export default PaymentMethod
