import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import FilterModel from '~/structures/filters/FilterModel'

export default class BulkSettingsFilter extends FilterModel {
  static defaultFilters = function () {
    return {
      textInput: '',
      fileInput: null,

      autofill: [autofillType.autofillNone],
      englishOnly: 'null',
      articleFoil: 'null',
      minCondition: 'HP',
      excludeExtras: [false],
    }
  }

  get parsedFilter() {
    const payload = {
      foil: this.foilValue,
      exclude_extras: this.shouldExcludeExtras,
      min_condition: this.minCondition
    }

    if(this.isAutofillEnabled && !isEmpty(this.autofill)) {
      payload.autofill = this.autofill[0]
    }
    if(this.isEnglishOnly) {
      payload.language = 'en'
    }

    return payload
  }

  get hasBothInputs() {
    return !isEmpty(this.textInput) && !isNull(this.fileInput)
  }

  get isAutofillEnabled() {
    return !isEqual(this.autofill, [autofillType.autofillNone])
  }

  get autofillsCheapest() {
    return this.autofillValue === autofillType.autofillCheapest
  }

  get autofillsBestCondition() {
    return this.autofillValue === autofillType.autofillBestCondition
  }

  get autofillValue() {
    let value = autofillType.autofillCheapest

    if(!isEmpty(this.autofill)) {
      value = this.autofill[0]
    }

    return value
  }

  get foilIgnoreChoice() {
    return this.articleFoil === 'null'
  }

  get foilRequest() {
    return this.articleFoil === 'true'
  }

  get nonfoilRequest() {
    return this.articleFoil === 'false'
  }

  get foilValue() {
    let value = this.foilRequest

    if(this.nonfoilRequest) {
      value = false
    } else if (this.foilIgnoreChoice) {
      value = null
    }

    return value
  }

  get isEnglishOnly() {
    return this.englishOnly === 'true'
  }

  get shouldExcludeExtras() {
    return isEqual(this.excludeExtras, ['true'])
  }
}

export const autofillType = {
  autofillNone: 'none',
  autofillCheapest: 'cheapest',
  autofillBestCondition: 'best_condition',
}

export const autofillOptions = {
  [autofillType.autofillNone]: 'filter_option_autofill_none',
  [autofillType.autofillCheapest]: 'filter_option_autofill_cheapest',
  [autofillType.autofillBestCondition]: 'filter_option_autofill_best_condition',
}
