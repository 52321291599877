import FilterModel from '@/structures/filters/FilterModel'

export default class BuylistSinglesFilter extends FilterModel {
  static defaultFilters = function (currency) {
    return {
      q: '',
      expansion: [],
      rarity: [],
      mtg_colors: ['or'],
      is_foil: [],
      buy_price: currency === 'czk' ? [0, 1000] : [0, 40]
    }
  }
}
