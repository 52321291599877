import map from 'lodash/map'

class ProductCondition {
  static options = {
    NM: 'Near Mint (NM)',
    EX: 'Excellent (EX)',
    GD: 'Good (GD)',
    PL: 'Played (PL)',
    HP: 'Heavily Played (HP)'
  }

  static optionsKeyArray = map(this.options, (value, key) => key)

  static optionsArray = map(ProductCondition.options, (value, key) => {
    return {
      id: key,
      title: value
    }
  })

  static shortcutOptionsArray = map(ProductCondition.options, (value, key) => {
    return {
      id: key,
      title: key
    }
  })
}

export default ProductCondition
