import isString from 'lodash/isString'

class Medium {
  // eslint-disable-next-line camelcase
  constructor ({ type, url, image_url_tiny, image_small, image_medium, image_large }) {
    this.type = type
    this.url = url
    // eslint-disable-next-line camelcase
    this.image_url_tiny = image_url_tiny
    // eslint-disable-next-line camelcase
    this.image_small = image_small
    // eslint-disable-next-line camelcase
    this.image_medium = image_medium
    // eslint-disable-next-line camelcase
    this.image_large = image_large
  }

  static Type = {
    image: 'IMAGE',
    video: 'VIDEO'
  }

  get isImage () {
    return this.type === Medium.Type.image
  }

  get isVideo () {
    return this.type === Medium.Type.video
  }

  get isYoutube () {
    return isString(this.url) && this.url.includes('youtu')
  }

  get processedUrl () {
    let url = this.url

    if (!isString(url)) return url

    if (this.isYoutube) {
      url = url.replace('watch?v=', 'embed/')
    }

    return url
  }

  get image () {
    return this.processedUrl
  }

  get imageTiny () {
    return this.image_url_tiny || this.imageSmall || this.image
  }

  get imageSmall () {
    return this.image_small || this.imageMedium || this.image
  }

  get imageMedium () {
    return this.image_medium || this.imageLarge || this.image
  }

  get imageLarge () {
    return this.image_large || this.image
  }
}

export default Medium
