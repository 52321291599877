import { dayWordDayMonthYear, isTomorrow, isToday } from '@/services/DateService'
import AutoCodableN from '~/database/models/AutoCodableN'

class ShippingMethod extends AutoCodableN {
  static entity = 'shipping_methods'
  static primaryKey = 'id'

  static fields () {
    return {
      id: this.attr(1),
      access_point_id: this.attr(''),
      estimated_delivery_max: this.attr(null),
      estimated_delivery_min: this.attr(null),
      cash_on_delivery_threshold_czk: this.attr(null),
      cash_on_delivery_threshold_eur: this.attr(null),
      etit_max: this.attr(0),
      etit_min: this.attr(0),
      icon: this.attr(null),
      is_valid: this.attr(false),
      localized_name: this.attr(''),
      name: this.attr(''),
      name_cz: this.attr(''),
      name_de: this.attr(''),
      additional_information: this.attr(null),
      additional_information_cz: this.attr(null),
      additional_information_de: this.attr(null),
      needs_access_point_info: this.attr(false),
      price_czk: this.attr(0),
      price_eur: this.attr(0),
      type: this.attr(null)
    }
  }

  static Type = {
    ZASILKOVNA: 'ZASILKOVNA',
    CZECH_POST: 'CZECH_POST',
    UPS: 'UPS',
    IN_STORE_PICKUP: 'IN_STORE_PICKUP',
    GLS: 'GLS'
  }

  get accessPointId () {
    return this.access_point_id
  }

  get titleLoc() {
    return this.getLoc('name')
  }

  get isValid () {
    return Boolean(this.is_valid)
  }

  get additionalInformationLoc() {
    this.getLoc('additional_information')
  }

  get estimatedDeliveryMax () {
    return this.estimated_delivery_max
  }

  get estimatedDeliveryMin () {
    return this.estimated_delivery_min
  }

  get estimatedDeliveryIsToday () {
    if (this.estimatedDeliveryMin) {
      return isToday(this.estimatedDeliveryMin)
    }

    return false
  }

  get estimatedDeliveryIsTomorrow () {
    if (this.estimatedDeliveryMin) {
      return isTomorrow(this.estimatedDeliveryMin)
    }

    return false
  }

  get estimatedDeliveryMinFormatted () {
    return dayWordDayMonthYear(this.estimatedDeliveryMin)
  }

  get needsAccessPointInfo () {
    return this.needs_access_point_info
  }

  get priceLoc() {
    return this.getLocCurrency('price')
  }

  get cashOnDeliveryThresholdLoc() {
    return this.getLocCurrency('cash_on_delivery_threshold')
  }
}

export default ShippingMethod
