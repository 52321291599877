const reduce = require('lodash/reduce')
const isEmpty = require('lodash/isEmpty')

module.exports = {
  /**
   * @param {NavigationPage | Object} page
   * @returns {boolean}
   */
  isCategoryOverview (page) {
    return !page.contains_products && !isEmpty(page.children)
  },

  /**
   * @param {NavigationPage | Object} page
   * @returns {number|*}
   */
  getCategoryId (page) {
    if (page.url_fragment === 'mtg') {
      return 21
    }

    return reduce(page.children, (current, item) => {
      if (item.category) return item.category
      return current
    }, null)
  }
}
