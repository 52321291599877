import { compareTwoStrings } from 'string-similarity'

import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isString from 'lodash/isString'
import {isValidPhoneNumber} from '~/structures/filters/PhoneValidator'

let commonPasswords = []

export function setCommonPasswords(passwords) {
  commonPasswords = passwords
}

export const none = function () {
  return true
}

export const isChecked = function (value) {
  return value || 'form_validation_check_required'
}

export const required = function (value) {
  return !isEmpty(value) || 'form_validation_empty'
}

export const notNull = function (value) {
  return (!isNil(value) && !isNull(value)) || 'form_validation_empty'
}

export const notNumeric = function (value) {
  return isNaN(value) || 'form_validation_password_numeric'
}

/**
 *
 * @param {number} sizeLimit File size limit in MB
 * @return {function(*)}
 */
export const fileSize = function (sizeLimit) {
  return (file) => {
    if (!file) return true
    const size = file.size / 1000 / 1000 // MB

    return size < sizeLimit || 'form_validation_file_size'
  }
}

export const password = function (emailGetter) {
  return value => {
    const minimalLength = minLength(8)(value)
    const isNotDigit = notNumeric(value)
    const hasNumber = containsNumber(value)
    const hasUppercaseLetter = containsUppercaseLetter(value)
    const similarity = compareTwoStrings(emailGetter(), value) <= 0.7 || 'form_validation_password_similarity'
    const isCommon = isCommonPassword(value)

    const values = [minimalLength, isNotDigit, hasNumber, hasUppercaseLetter, similarity, isCommon]

    for (let i = 0; i < values.length; i++) {
      if (isString(values[i])) {
        return values[i]
      }
    }
  }
}

export const isCommonPassword = function (value) {
  return !commonPasswords.includes(`${value}`.toLowerCase()) || 'form_validation_password_common'
}

/**
 * @param {function} passwordGetter
 * @return {function(*): boolean|string}
 */
export const passwordConfirm = function (passwordGetter) {
  return (passwordConfirmValue) => {
    return passwordGetter() === passwordConfirmValue || 'form_validation_password_confirm_mismatch'
  }
}

/**
 * @param {function(): string} countryGetter
 * @return {function(string): boolean|string}
 */
export const zip = function (countryGetter) {
  return (zip) => {
    let value = true

    if(countryGetter() === 'CZ') {
      const trimmedZip = zip.replace(/\s/g, '')
      const zipNumber = parseInt(trimmedZip)

      value = trimmedZip.length === 5 && !isNaN(zipNumber) && (zipNumber > 9999 && zipNumber < 80000)
    }

    return value || 'form_validation_zip_country_invalid'
  }
}

export const minLength = function (length = 1) {
  return (value) => {
    if (isNull(value)) return true
    return value.length >= length || 'form_validation_min_length'
  }
}

export const maxLength = function (length = 1) {
  return (value) => {
    if (isNull(value)) return true
    return value.length <= length || 'form_validation_max_length'
  }
}

export const phoneNumber = function ({ code, number }) {
  const phoneNumber = [code, number].join('')
  return phoneNumber && isValidPhoneNumber(phoneNumber) || 'form_validation_phone_length'
}

export const email = function (value) {
  // eslint-disable-next-line no-useless-escape
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value) ||
    'form_validation_email_invalid'
}

export const username = function (value) {
  if (isNull(value) || isEmpty(value)) return true
  if (value.length < 4) return 'form_validation_min_length'
  if (value.length > 50) return 'form_validation_max_length'
  if (!value.match(/^[a-z0-9_-]+$/i)) return 'form_validation_alphanumeric'
  if (!value.match(/^[a-z0-9]/i)) return 'form_validation_alphanumeric_start'

  return true
}

export function containsNumber (value) {
  return /\d/.test(value) || 'form_validation_number_required'
}

export function containsUppercaseLetter (value) {
  return /[A-Z]/.test(value) || 'form_validation_uppercase_letter_required'
}
