const each = require('lodash/each')

module.exports = {
  /**
   *
   * @param {Array} data
   * @return {Object}
   */
  processTranslationData (data) {
    const translationsObject = {
      cs: {},
      en: {}
    }

    each(data, translation => {
      translationsObject.cs[translation.msgid] = translation.translation_cs
      translationsObject.en[translation.msgid] = translation.translation_en
    })

    return translationsObject
  }
}
