import AutoCodableN from '~/database/models/AutoCodableN'

export default class Payment extends AutoCodableN {
  static fields () {
    return {
      amount: this.attr(0),
      payment_method: this.attr(null),
      payment_status: this.attr(null)
    }
  }

  get paymentStatus () {
    return this.payment_status
  }

  get type () {
    let type = null

    if (this.paymentMethod && 'type' in this.paymentMethod) {
      type = this.paymentMethod.type
    }

    return type
  }

  get paymentMethod () {
    return { ...(this.payment_method || {}), price: this.amount }
  }
}
