/**
 * @template {T}
 */
class FetchResult {
  /**
   *
   * @param {T[]} data
   * @param {{}} params
   * @param {{}} summary
   * @param {Number} total
   * @param {Boolean} hasMore
   */
  constructor ({ data = [], params = {}, summary = {}, total = 0, hasMore = false }) {
    /** @type {Array<T>} */
    this.data = data
    this.params = params
    this.totalCount = total
    this.hasMore = hasMore
    this.summary = summary
  }

  /**
   *
   * @param {{}} result
   * @return {FetchResult}
   */
  static cloneFrom(result) {
    return new FetchResult({
      data: result.data,
      params: result.params,
      total: result.totalCount,
      hasMore: result.hasMore,
      summary: result.summary
    })
  }
}

export default FetchResult
