import map from 'lodash/map'
import { articlePriceMarks } from '~/services/filters/SliderFilters'

import DropdownItem from '@/components/general/DropdownItem'
import { sortKeys } from '@/services/FilterService'
import { FormField } from '@/structures/FormField'

class FilterConfig {
  constructor (props) {
    this.items = {}

    for (const itemKey in props) {
      if (!(itemKey in props)) continue
      const item = props[itemKey]

      this.items[itemKey] = {
        type: item.type,
        options: map(item.options, function (option) {
          return new DropdownItem(option)
        }),
        config: item.config || {}
      }
    }
  }

  setItemForKey(key, item) {
    this.items[key] = {
      type: item.type,
      options: map(item.options, function (option) {
        return new DropdownItem(option)
      }),
      config: item.config || {}
    }
  }

  static MTGGame = '40e972fe-c136-4d66-b61a-89f6be0f93d5'

  static types = {
    Dropdown: 'Dropdown',
    PriceRange: 'PriceRange',
    Checkbox: 'CheckboxAction',
    Spacer: 'Spacer'
  }

  static fields = {
    sort: {
      type: FilterConfig.types.Dropdown,
      options: [
        new DropdownItem({ value: sortKeys.nameAsc, label: 'filter_sort_name-asc' }),
        new DropdownItem({ value: sortKeys.nameDesc, label: 'filter_sort_name-desc' }),
        new DropdownItem({ value: sortKeys.priceAsc, label: 'filter_sort_price-asc' }),
        new DropdownItem({ value: sortKeys.priceDesc, label: 'filter_sort_price-desc' }),
        new DropdownItem({ value: sortKeys.releaseAsc, label: 'filter_sort_added-asc' }),
        new DropdownItem({ value: sortKeys.releaseDesc, label: 'filter_sort_added-desc' }),
        new DropdownItem({ value: sortKeys.discount, label: 'filter_sort_discount' }),
        new DropdownItem({ value: sortKeys.popularityDesc, label: 'filter_sort_popularity-desc' })
      ]
    },
    newSort: {
      type: FilterConfig.types.Dropdown,
      options: [
        new DropdownItem({ value: sortKeys.addedDesc, label: 'filter_sort_added-desc' }),
        new DropdownItem({ value: sortKeys.nameAsc, label: 'filter_sort_name-asc' }),
        new DropdownItem({ value: sortKeys.nameDesc, label: 'filter_sort_name-desc' }),
        new DropdownItem({ value: sortKeys.priceAsc, label: 'filter_sort_price-asc' }),
        new DropdownItem({ value: sortKeys.priceDesc, label: 'filter_sort_price-desc' }),
        new DropdownItem({ value: sortKeys.discount, label: 'filter_sort_discount' }),
        new DropdownItem({ value: sortKeys.popularityDesc, label: 'filter_sort_popularity-desc' })
      ]
    },
    presaleSort: {
      type: FilterConfig.types.Dropdown,
      options: [
        new DropdownItem({ value: sortKeys.nameAsc, label: 'filter_sort_name-asc' }),
        new DropdownItem({ value: sortKeys.nameDesc, label: 'filter_sort_name-desc' }),
        new DropdownItem({ value: sortKeys.priceAsc, label: 'filter_sort_price-asc' }),
        new DropdownItem({ value: sortKeys.priceDesc, label: 'filter_sort_price-desc' }),
        new DropdownItem({ value: sortKeys.releaseAsc, label: 'filter_sort_added-asc' }),
        new DropdownItem({ value: sortKeys.releaseDesc, label: 'filter_sort_added-desc' }),
        new DropdownItem({ value: sortKeys.discount, label: 'filter_sort_discount' }),
        new DropdownItem({ value: sortKeys.popularityDesc, label: 'filter_sort_popularity-desc' })
      ]
    },
    singlesSort: {
      type: FilterConfig.types.Dropdown,
      options: [
        new DropdownItem({ value: sortKeys.nameAsc, label: 'filter_sort_name-asc' }),
        new DropdownItem({ value: sortKeys.nameDesc, label: 'filter_sort_name-desc' }),
        new DropdownItem({ value: sortKeys.priceAsc, label: 'filter_sort_price-asc' }),
        new DropdownItem({ value: sortKeys.priceDesc, label: 'filter_sort_price-desc' }),
        new DropdownItem({ value: sortKeys.releaseDesc, label: 'filter_sort_added-desc' }),
        new DropdownItem({ value: sortKeys.collnumAsc, label: 'filter_sort_collnum-asc' }),
        new DropdownItem({ value: sortKeys.collnumDesc, label: 'filter_sort_collnum-desc' })
      ]
    },
    buylistSort: {
      type: FilterConfig.types.Dropdown,
      options: [
        new DropdownItem({ value: sortKeys.nameAsc, label: 'filter_sort_name-asc' }),
        new DropdownItem({ value: sortKeys.nameDesc, label: 'filter_sort_name-desc' }),
        new DropdownItem({ value: sortKeys.priceAsc, label: 'filter_sort_price-asc' }),
        new DropdownItem({ value: sortKeys.priceDesc, label: 'filter_sort_price-desc' }),
        new DropdownItem({ value: sortKeys.collnumAsc, label: 'filter_sort_collnum-asc' }),
        new DropdownItem({ value: sortKeys.collnumDesc, label: 'filter_sort_collnum-desc' }),
        new DropdownItem({ value: sortKeys.sellReleaseDesc, label: 'filter_sort_added-desc' })
      ]
    },
    searchModule: {
      type: FilterConfig.types.Dropdown,
      options: [
        new DropdownItem({ value: 'all', label: 'search_category-all' }),
        new DropdownItem({ value: 'mtg_singles', label: 'search_category-mtg-singles' }),
        new DropdownItem({ value: 'mtg_buylist', label: 'search_category-mtg-buylist' })
        // new DropdownItem({ value: 'card_games', label: 'search_category-card-games' }), //
        // new DropdownItem({ value: 'tabletop_games', label: 'search_category-tabletop_games' }),
        // new DropdownItem({ value: 'miniatures', label: 'search_category-miniatures' }),
        // new DropdownItem({ value: 'comics', label: 'search_category-comics' })
      ]
    },
    inStock: {
      type: FilterConfig.types.Checkbox,
      options: [],
      config: {
        field: new FormField({ type: 'checkbox', name: 'inStock', label: 'filter_instock' })
      }
    },
    inStockShop: {
      type: FilterConfig.types.Checkbox,
      options: [],
      config: {
        field: new FormField({ type: 'checkbox', name: 'inStock', label: 'filter_instock_shop' })
      }
    },
    enabledForBuylist: {
      type: FilterConfig.types.Checkbox,
      options: [],
      config: {
        field: new FormField({ type: 'checkbox', name: 'enabled', label: 'filter_enabled_for_buylist' })
      }
    },
    priceRange: {
      type: FilterConfig.types.PriceRange,
      options: [],
      config: {
        min: 0,
        max: 1000,
        step: false,
        marks: articlePriceMarks(),
        labelPattern: '{value}&nbsp;CZK'
      }
    },
    spacer: {
      type: FilterConfig.types.Spacer,
      options: [],
      config: {}
    },
  }

  static getFields (fields, options) {
    const fieldsCopy = fields
    fieldsCopy.options = map(options, option => new DropdownItem(option))

    return fieldsCopy
  }
}

export default FilterConfig

export const NonspecificExpansionGame = '2d1eee1a-5388-44ae-b4b1-5683dcfb96b6'
