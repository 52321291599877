import { findObject } from '@/services/Helpers'

class FormSection {
  constructor ({ title = null, fields = [] }) {
    Object.assign(this, { title, fields })
  }

  /**
   *
   * @param {String} fieldName Name of the field
   * @param {String} fieldProperty Name of the property to change
   * @param {any} value Value of the property
   */
  updateField (fieldName, fieldProperty, value) {
    /** @type {FormField|null} */
    const field = findObject(this.fields, field => field.name === fieldName)

    if (field) {
      field.setConfigItem(fieldProperty, value)
    }
  }
}

export default FormSection
