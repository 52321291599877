import each from 'lodash/each'
import flatMap from 'lodash/flatMap'

class FormValidationManager {
  showValidationMessages = false
  validationModel = {}

  /**
   *
   * @param {Object} props
   * @param {Object} props.model Data model of the form
   * @param {Array<FormSection>} props.sections
   */
  constructor (props) {
    const oThis = this
    this.fields = {}

    const flatFields = flatMap(props.sections, section => section.fields)

    each(flatFields, /** @type FormField */ field => {
      oThis.validationModel[field.modelName] = false
      oThis.fields[field.modelName] = field
    })

    this.updateModel(props.model)
  }

  /**
   * @type {boolean}
   */
  get isValidationVisible () {
    return this.showValidationMessages
  }

  /**
   *
   * @param {String} name
   * @param {any} value
   */
  updateModelValue (name, value) {
    this.model[name] = value
    this.validateField(name)
  }

  /**
   *
   * @param {Object} model
   */
  updateModel (model = {}) {
    this.model = model
    const modelKeys = Object.keys(this.fields)

    for (let i = 0; i < modelKeys.length; i++) {
      this.validateField(modelKeys[i])
    }
  }

  /**
   * Updates sections references when rules are updated in the form config
   *
   * @param {FormSection[]} sections
   */
  updateSections (sections) {
    const oThis = this
    const flatFields = flatMap(sections, section => section.fields)

    each(flatFields, /** @type FormField */ field => {
      oThis.fields[field.modelName] = field
    })
  }

  /**
   *
   * @param {Boolean} showValidation
   */
  validate (showValidation = true) {
    this.showValidationMessages = showValidation

    let status = true
    const modelKeys = Object.keys(this.validationModel)

    for (let i = 0; i < modelKeys.length; i++) {
      this.validateField(modelKeys[i])
      const validationStatus = this.validationModel[modelKeys[i]]

      if (validationStatus) {
        status = false
        break
      }
    }

    return status
  }

  /**
   *
   * @param {String} name
   */
  validateField (name) {
    /** @type {FormField | null} */
    const field = this.fields[name]

    if (field) {
      let status = false

      for (let i = 0; i < field.rules.length; i++) {
        const ruleResult = field.rules[i](this.model[name])

        if (ruleResult !== true) {
          status = ruleResult
          break
        }
      }

      this.validationModel[name] = status
    }
  }
}

export default FormValidationManager
