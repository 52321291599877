import each from 'lodash/each'
import isArray from 'lodash/isArray'
import isEmptyLodash from 'lodash/isEmpty'
import isString from 'lodash/isString'

export default class FilterModel {
  constructor (currency = 'czk') {
    this.applyDefault(currency)
  }

  /**
   *
   * @param {string} currency
   * @returns {object}
   */
  static defaultFilters = function (currency) {
    return {}
  }

  getActiveItemsCount (currency = 'czk', ignored = []) {
    const oThis = this
    const defaultValueObject = this.constructor.defaultFilters(currency)
    const defaultValueKeys = Object.keys(defaultValueObject)

    let count = 0

    each(defaultValueKeys, key => {
      if(ignored.includes(key)) return

      const defaultValue = defaultValueObject[key]

      if (isArray(defaultValue)) {
        if (isEmptyLodash(defaultValue)) {
          count += oThis[key].length
        } else if (!(defaultValue.length === oThis[key].length && defaultValue.every((value, index) => value === oThis[key][index]))) {
          count++
        }
      } else if (isString(defaultValue)) {
        if (defaultValue !== oThis[key]) {
          count++
        }
      }
    })

    return count
  }

  /**
   *
   * @param {string} currency
   * @returns {boolean}
   */
  isEmpty (currency = 'czk') {
    const oThis = this
    const defaultValueObject = this.constructor.defaultFilters(currency)
    const defaultValueKeys = Object.keys(defaultValueObject)

    let isEmpty = true

    each(defaultValueKeys, key => {
      const defaultValue = defaultValueObject[key]

      if (isArray(defaultValue)) {
        if (isEmptyLodash(defaultValue)) {
          isEmpty = isEmptyLodash(oThis[key])
        } else {
          isEmpty = defaultValue.length === oThis[key].length && defaultValue.every((value, index) => value === oThis[key][index])
        }
      }

      if (isString(defaultValue)) {
        isEmpty = defaultValue === oThis[key]
      }

      return isEmpty
    })

    return isEmpty
  }

  /**
   *
   * @param {string} key
   * @param {any} value
   */
  setKey (key, value) {
    if (key in this) {
      this[key] = value
    }
  }

  /**
   * Apply filter object values to current model
   *
   * @param {object} filter
   */
  setKeys (filter = {}) {
    const oThis = this
    const keys = Object.keys(filter)

    each(keys, key => {
      oThis.setKey(key, filter[key])
    })
  }

  /**
   *
   * @param {string} key
   * @param {any} value
   */
  removeArrayKey (key, value) {
    if (key in this && isArray(this[key])) {
      const index = this[key].indexOf(value)
      if (index > -1) {
        this[key].splice(index, 1)
      }
    }
  }

  /**
   *
   * @param {string} key
   * @param {any} value
   */
  toggleArrayKey (key, value) {
    if (key in this) {
      const filter = this[key]

      const set = new Set(filter)

      if (set.has(value)) {
        set.delete(value)
      } else {
        set.add(value)
      }

      this[key] = Array.from(set)
    }
  }

  /**
   *
   * @param {string} currency
   */
  reset (currency = 'czk') {
    this.applyDefault(currency)
  }

  /**
   *
   * @param {string} currency
   */
  applyDefault (currency = 'czk') {
    Object.assign(this, this.constructor.defaultFilters(currency))
  }
}
